import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";

import { dispatch } from "../index";
import { Meeting } from "../../types/meeting";
import { meetingClient } from "../../services/api/manual";

import {
  setIsLoggedIn,
  setLoggedInCompany,
  setLoggedInShow,
  setRole,
} from "./manualUser";
import { setSelectedShow } from "./show";

import { DefaultRootStateProps } from "types";
import { tokenStorage } from "services/storage";

const initialState: DefaultRootStateProps["meeting"] = {
  error: null,
  meetings: [],
  currentMeeting: null,
};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getMeetingsSuccess(state, action: PayloadAction<Meeting[]>) {
      state.meetings = action.payload;
    },
    setCurrentMeeting(state, action: PayloadAction<Meeting | null>) {
      state.currentMeeting = action.payload;
    },
  },
});

export function getMeetings(showId: number, navigate: NavigateFunction) {
  return async () => {
    return meetingClient
      .getMeetings(showId)
      .then((meetings) => {
        dispatch(meetingSlice.actions.getMeetingsSuccess(meetings));
      })
      .catch((error) => {
        dispatch(meetingSlice.actions.hasError(error));
        if (error.response && error.response.status === 401) {
          console.error(error);

          dispatch(setIsLoggedIn(false));
          dispatch(setSelectedShow({ showId: null, showName: null }));
          dispatch(setLoggedInCompany(null));
          dispatch(setLoggedInShow(null));
          dispatch(setRole(null));

          toast.success("Session expired, please login again.");
          tokenStorage.removeToken();
          navigate("/manual/login");
        }
      });
  };
}

export function getMeetingById(meeting: Meeting) {
  return async () => {
    try {
      dispatch(meetingSlice.actions.setCurrentMeeting(meeting));
    } catch (error: any) {
      dispatch(meetingSlice.actions.hasError(error));
    }
  };
}

export default meetingSlice.reducer;
