import axios from "axios";

import { httpClient } from "../httpClient";
import { Document, PreSignedDocument } from "../../../types/document";
import { ADMIN_DOC_API_URL, USER_DOC_API_URL } from "../../../config";

function getDocuments(showId: number): Promise<Document[]> {
  return httpClient
    .get(`${USER_DOC_API_URL}/shows/${showId}/documents`)
    .then((resp) => {
      return resp.data?.documents ?? [];
    })
    .catch((error) => {
      throw error;
    });
}

function getDocumentByID(documentId: number): Promise<PreSignedDocument> {
  return httpClient
    .get(`${USER_DOC_API_URL}/shows/documents/${documentId}`)
    .then((resp) => {
      return resp.data ?? null;
    });
}

function deleteDocumentByID(documentId: number): Promise<any> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/document/${documentId}`)
    .then((resp) => {
      return resp.data ?? null;
    });
}

function getUpdates(showId: number) {
  return httpClient
    .get(`${USER_DOC_API_URL}/shows/${showId}/recently_updated_documents`)
    .then((resp) => {
      return resp.data ?? [];
    });
}

async function createDocument(document: any, showId: number, file: File) {
  try {
    const presignedUrl = await documentClient.uploadNewDocument(
      document,
      showId
    );

    const response = await axios.put(presignedUrl.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    if (response.status !== 200) {
      throw new Error(`S3 upload failed with status ${response.status}`);
    }

    console.info("File successfully uploaded to S3");
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw error;
  }
}

function uploadNewDocument(document: Document, showId: number) {
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/show/${showId}/document`, document)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function uploadDocument(document: Document, showId: number) {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/show/${showId}/document`, document)
    .then((resp) => {
      return resp.data ?? {};
    });
}

async function updateDocument(
  showId: number,
  document: any,
  file: File | null
) {
  try {
    const presignedUrl = await documentClient.uploadDocument(document, showId);

    if (file) {
      const response = await axios.put(presignedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (response.status !== 200) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      console.info("File successfully uploaded to S3");
    }
  } catch (error) {
    console.error("Error in updateDocument:", error);
    throw error;
  }
}

function archiveDocument(documentId: number) {
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/document/archive/${documentId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function archiveMultipleDocuments(documentIds: number[]): Promise<void> {
  const archivePromises = documentIds.map((documentId) =>
    archiveDocument(documentId).catch((error) => {
      console.error(`Failed to archive document ID ${documentId}:`, error);

      return null;
    })
  );

  return Promise.all(archivePromises)
    .then(() => {
      console.info("All documents have been processed for archiving.");
    })
    .catch((error) => {
      console.error(
        "An unexpected error occurred while archiving documents:",
        error
      );
    });
}

export const documentClient = {
  createDocument,
  getDocuments,
  getDocumentByID,
  deleteDocumentByID,
  getUpdates,
  uploadNewDocument,
  uploadDocument,
  updateDocument,
  archiveDocument,
  archiveMultipleDocuments,
};
