import { useState } from "react";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  useField,
} from "formik";
import * as Yup from "yup";

import { CategoryItem, Region, categories, regions } from "./modalData";

import { useDispatch } from "store";
import { closeUpdateDocumentModal } from "store/slices/modal";
import { getDocuments } from "store/slices/document";
import { documentClient } from "services/api/manual";
import DragDrop from "components/DragDrop/DragDrop";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  documentId: number | null;
  documentTitle: string;
  documentCategory: string | null;
  documentSubCategory: string | null;
  documentRegion: string | null;
  documentFile: File | null;
}

interface FormikSelectProps extends FieldProps {
  label: string;
  children: React.ReactNode;
}
interface FormikErrorTextProps {
  children?: React.ReactNode;
}

const FormikErrorText = ({ children }: FormikErrorTextProps) => (
  <Typography style={{ color: "red", marginTop: 4, marginLeft: 4 }}>
    {children === "File is required" ? "Please upload a file" : children}
  </Typography>
);

const FormikSelect = ({ label, children, ...props }: FormikSelectProps) => {
  const [field, meta] = useField(props.field.name);

  return (
    <FormControl
      sx={{
        "& .MuiSelect-select": {
          background: FORM_FIELD_BG_COLOR,
        },
        "& .MuiFormHelperText-root": { background: "#fff" },
      }}
      fullWidth
      error={meta.touched && !!meta.error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props.field}
        label={label}
        error={meta.touched && !!meta.error}
      >
        {children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const findCategoryId = (categoryName: string | null) => {
  const category = categories.find((c) => c.category?.name === categoryName);
  return category ? category.category?.id : null;
};

const findSubCategoryId = (subcategoryName: string | null) => {
  for (const categoryItem of categories) {
    const subCategory = categoryItem.subCategories.find(
      (sc) => sc.name === subcategoryName
    );
    if (subCategory) {
      return subCategory.id;
    }
  }
  return null;
};

const findRegionId = (regionName: string | null) => {
  const region = regions.find((r) => r.name === regionName);
  return region ? region.id : null;
};

const UpdateDocumentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const selectedShow = useSelector((state: any) => state.show.selectedShow);
  const updateDocumentModalOpen = useSelector(
    (state: any) => state.modal.updateDocumentModalOpen
  );
  const updateDocumentModalParamsId = useSelector(
    (state: any) => state.modal.updateDocumentModalParams.id
  );
  const documentList = useSelector((state: any) => state.document.documents);
  const selectedDocument = documentList.find(
    (item: any) => item.documentId === updateDocumentModalParamsId
  );
  const documentCategory = useSelector(
    (state: any) => state.modal.createDocumentModalParams.documentCategory
  );
  const filteredCategories = categories.filter(
    (category) => category.category?.id === documentCategory
  );

  const initialValues: FormValues = {
    documentId: selectedDocument?.documentId || null,
    documentTitle: selectedDocument?.documentName || "",
    documentCategory: selectedDocument?.category || "",
    documentSubCategory: selectedDocument?.subcategory || "",
    documentRegion: selectedDocument?.region || "",
    documentFile: null,
  };

  const validationSchema = Yup.object().shape({
    documentTitle: Yup.string().required("Required"),
    documentCategory: Yup.string().required("Required"),
    documentRegion: Yup.string().required("Required"),
    documentFile: Yup.mixed().required("File is required"),
  });

  const handleUpdate = async (showId: number, values: FormValues) => {
    if (values) {
      let documentFileName = "";

      if (values.documentFile instanceof File) {
        documentFileName = values.documentFile.name;
      } else {
        documentFileName = selectedDocument?.documentPath || "";
      }

      const document = {
        documentId: selectedDocument?.documentId,
        category: {
          categoryId: findCategoryId(values.documentCategory),
        },
        subcategory: {
          subcategoryId: findSubCategoryId(values.documentSubCategory),
        },
        region: {
          regionId: findRegionId(values.documentRegion),
        },
        documentName: values.documentTitle,
        documentFileName: documentFileName,
        documentInfo: "",
        updateInfo: "",
      };

      try {
        setLoading(true);
        await documentClient.updateDocument(
          showId,
          document,
          values.documentFile as File
        );
        setLoading(false);
        toast.success("Successfully updated");
        dispatch(getDocuments(showId, navigate));
      } catch (error) {
        toast.error("Error updating document");
        console.error("Error in updating document:", error);
      } finally {
        dispatch(closeUpdateDocumentModal());
      }
    }
  };

  const onSubmit = (values: FormValues) => {
    handleUpdate(selectedShow.showId, values);
  };

  return (
    <Dialog
      open={updateDocumentModalOpen}
      onClose={() => dispatch(closeUpdateDocumentModal())}
    >
      <DialogTitle variant="h3" sx={{ p: "16px" }}>
        {`Update ${selectedDocument?.documentName}`}
      </DialogTitle>
      <DialogContent sx={{ p: "16px" }}>
        <Typography variant="caption">
          Please update the fields below.
        </Typography>
      </DialogContent>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <Grid container spacing={3} sx={{ p: "16px" }}>
              <Grid item xs={12} sm={12}>
                <Field
                  as={TextField}
                  sx={{ background: FORM_FIELD_BG_COLOR }}
                  id="documentTitle"
                  name="documentTitle"
                  label="Document Title"
                  fullWidth
                  error={Boolean(errors.documentTitle && touched.documentTitle)}
                  helperText={touched.documentTitle && errors.documentTitle}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentCategory"
                  label="Document Category"
                  fullWidth
                  error={Boolean(
                    errors.documentCategory && touched.documentCategory
                  )}
                  helperText={
                    touched.documentCategory && errors.documentCategory
                  }
                >
                  {categories
                    .filter(
                      (c) => c.category?.name === selectedDocument?.category
                    )
                    .map(({ category }: CategoryItem) => (
                      <MenuItem
                        sx={{ p: "4px 8px" }}
                        key={nanoid()}
                        value={category?.name}
                      >
                        {category?.name}
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentSubCategory"
                  label="Document Subcategory"
                  fullWidth
                  error={Boolean(
                    errors.documentSubCategory && touched.documentSubCategory
                  )}
                  helperText={
                    touched.documentSubCategory && errors.documentSubCategory
                  }
                >
                  {filteredCategories.map(({ subCategories }: CategoryItem) => [
                    ...subCategories.map((subcategory) => (
                      <MenuItem
                        sx={{ p: "4px 8px" }}
                        key={nanoid()}
                        value={subcategory.name}
                      >
                        <Typography>{subcategory.name}</Typography>
                      </MenuItem>
                    )),
                  ])}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentRegion"
                  label="Document Region"
                  fullWidth
                  error={Boolean(
                    errors.documentRegion && touched.documentRegion
                  )}
                  helperText={touched.documentRegion && errors.documentRegion}
                >
                  {regions.map((region: Region) => (
                    <MenuItem key={nanoid()} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} sm={12}>
                <DragDrop
                  onFileSelect={(file) => {
                    setFieldValue("documentFile", file);
                  }}
                />
                <ErrorMessage name="documentFile" component={FormikErrorText} />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  sx={{ display: "flex", justifyContent: "end" }}
                  container
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          sx={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      onClick={() => {
                        dispatch(closeUpdateDocumentModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateDocumentModal;
