import { Meeting, PartialMeeting } from "../../../types/meeting";
import { httpClient } from "../httpClient";
import { ADMIN_DOC_API_URL, USER_DOC_API_URL } from "../../../config";

export function getMeetings(showId: number): Promise<Meeting[]> {
  return httpClient
    .get(`${USER_DOC_API_URL}/shows/${showId}/meetings`)
    .then((resp) => {
      return resp.data?.meetings ?? [];
    });
}

export function updateMeeting(meeting: Meeting): Promise<Meeting> {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/meeting/${meeting.meetingId}`, meeting)
    .then((resp) => {
      return resp.data?.meetings ?? [];
    });
}

export function deleteMeeting(meetingId: number): Promise<any> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/meeting/${meetingId}`)
    .then((resp) => {
      return resp.data?.meetings ?? [];
    });
}

export function deleteMultipleMeetings(meetingIds: number[]): Promise<void> {
  const deletePromises = meetingIds.map((meetingId) =>
    deleteMeeting(meetingId).catch((error) => {
      console.error(
        `An error occurred while deleting meeting ID ${meetingId}:`,
        error
      );
      return null;
    })
  );

  return Promise.all(deletePromises).then(() => {
    console.info("All meetings processed for deletion.");
  });
}

export function createMeeting(
  showId: number,
  meeting: PartialMeeting
): Promise<Meeting> {
  const body = {
    showId,
    ...meeting,
  };
  return httpClient.post(`${ADMIN_DOC_API_URL}/meeting`, body).then((resp) => {
    return resp.data ?? [];
  });
}

export const meetingClient = {
  getMeetings,
  updateMeeting,
  deleteMeeting,
  createMeeting,
  deleteMultipleMeetings,
};
