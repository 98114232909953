import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Box, Grid } from "@mui/material";

import { useCustomTheme } from "themes";
import customParser from "utils/customParser";

const FONT_FAMILY = "Acumin-RPro, Roboto, sans-serif";

const StyledBox = styled(Box)({
  "& h1": {
    fontFamily: FONT_FAMILY,
    fontSize: "24px",
  },
  "& h2": {
    fontFamily: FONT_FAMILY,
    fontSize: "20px",
  },
  "& p": {
    fontFamily: FONT_FAMILY,
    fontSize: "14px",
  },
  "& ol": {
    fontFamily: FONT_FAMILY,
    fontSize: "14px",
    listStyleType: "decimal",
    paddingLeft: "20px",
  },
  "& ul": {
    fontFamily: FONT_FAMILY,
    fontSize: "14px",
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  "& li": {
    marginBottom: "4px",
  },
  "& a": {
    textDecoration: "underline",
    color: "blue",
  },
});

const Welcome = () => {
  const theme = useCustomTheme();
  const welcomeMessage = useSelector(
    (state: any) => state.show.show.welcomeMessage
  );

  return (
    <Grid
      item
      xs={12}
      sx={{
        p: "32px 16px",
        bgcolor: "#fff",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.tertiary.main}`,
      }}
    >
      <Box>
        {welcomeMessage && (
          <StyledBox>{customParser(welcomeMessage)}</StyledBox>
        )}
      </Box>
    </Grid>
  );
};

export default Welcome;
