import axios from "axios";

import { httpClient } from "../httpClient";

import { Template } from "types/template";
import { ADMIN_DOC_API_URL } from "config";
import { Meeting } from "types/meeting";

function getTemplates(prodCompanyId: number): Promise<Template[]> {
  return httpClient
    .get(`${ADMIN_DOC_API_URL}/templates`, {
      params: {
        prodCompanyId: prodCompanyId,
      },
    })
    .then((resp) => {
      return resp.data ?? [];
    });
}

function createTemplate(
  template: Omit<Template, "id" | "meetings" | "documents">
): Promise<Template> {
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/templates`, template)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function updateTemplate(
  templateId: number,
  template: Template
): Promise<Template> {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/templates/${templateId}`, template)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function deleteTemplate(templateId: number): Promise<any> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/templates/${templateId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function CreateTemplateMeeting(
  templateId: number,
  meeting: Omit<Meeting, "meetingId">
): Promise<Meeting> {
  return httpClient
    .post(`${ADMIN_DOC_API_URL}/templates/${templateId}/meetings`, meeting)
    .then((resp) => {
      return resp.data ?? [];
    });
}

export async function createTemplateDocument(
  document: any,
  templateId: number,
  file: File
) {
  try {
    const response = await uploadTemplateDocument(templateId, document);

    const uploadResponse = await axios.put(response.putPresignedURL, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error(`S3 upload failed with status ${response.status}`);
    }

    console.info("File successfully uploaded to S3");
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw error;
  }
}

function uploadTemplateDocument(templateId: number, document: Document) {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/templates/${templateId}/documents`, document)
    .then((resp) => {
      return resp.data ?? [];
    });
}

async function updateTemplateDocument(
  templateId: number,
  document: any,
  file: File | null
) {
  try {
    const { putPresignedURL } = await uploadTemplateDocument(
      templateId,
      document
    );

    if (file) {
      const response = await axios.put(putPresignedURL, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (response.status !== 200) {
        throw new Error(`File upload failed with status ${response.status}`);
      }

      console.log("File successfully uploaded to S3");
    }
  } catch (error) {
    console.error("Error in updateDocument:", error);
    throw error;
  }
}

function updateTemplateMeeting(
  templateId: number,
  meeting: Meeting
): Promise<any> {
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/templates/${templateId}/meetings`, meeting)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function deleteTemplateDocument(documentId: number): Promise<any> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/document/${documentId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function deleteMultipleTemplateDocuments(documentIds: number[]): Promise<void> {
  const deletePromises = documentIds.map((documentId) =>
    deleteTemplateDocument(documentId).catch((error) => {
      console.error(
        `An error occurred while deleting meeting ID ${documentId}:`,
        error
      );
      return null;
    })
  );
  return Promise.all(deletePromises).then(() => {
    console.info("All documents processed for deletion.");
  });
}

function deleteTemplateMeeting(meetingId: number): Promise<any> {
  return httpClient
    .delete(`${ADMIN_DOC_API_URL}/meeting/${meetingId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

function deleteMultipleTemplateMeetings(meetingIds: number[]): Promise<void> {
  const deletePromises = meetingIds.map((meetingId) =>
    deleteTemplateMeeting(meetingId).catch((error) => {
      console.error(
        `An error occurred while deleting meeting ID ${meetingId}:`,
        error
      );
      return null;
    })
  );
  return Promise.all(deletePromises).then(() => {
    console.info("All meetings processed for deletion.");
  });
}

function archiveDocument(templateId: number, document: any) {
  const body = { ...document, isDeleted: true };
  return httpClient
    .put(`${ADMIN_DOC_API_URL}/templates/${templateId}/documents`, body)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export const templateClient = {
  getTemplates,
  createTemplate,
  updateTemplate,
  updateTemplateDocument,
  updateTemplateMeeting,
  createTemplateDocument,
  CreateTemplateMeeting,
  deleteTemplate,
  deleteTemplateMeeting,
  deleteMultipleTemplateMeetings,
  deleteTemplateDocument,
  deleteMultipleTemplateDocuments,
  archiveDocument,
};
