import { useSelector } from "react-redux";

const useModalState = () => {
  return {
    createProductionModalOpen: useSelector(
      (state: any) => state.modal.createProductionModalOpen
    ),
    createTemplateProductionModalOpen: useSelector(
      (state: any) => state.modal.createTemplateProductionModalOpen
    ),
    createDocumentModalOpen: useSelector(
      (state: any) => state.modal.createDocumentModalOpen
    ),
    createTemplateDocumentModalOpen: useSelector(
      (state: any) => state.modal.createTemplateDocumentModalOpen
    ),
    createMeetingModalOpen: useSelector(
      (state: any) => state.modal.createMeetingModalOpen
    ),
    createTemplateMeetingModalOpen: useSelector(
      (state: any) => state.modal.createTemplateMeetingModalOpen
    ),
    createUserModalOpen: useSelector(
      (state: any) => state.modal.createUserModalOpen
    ),
    createTemplateModalOpen: useSelector(
      (state: any) => state.modal.createProductionTemplateModalOpen
    ),
    updatePasswordModalOpen: useSelector(
      (state: any) => state.modal.updatePasswordModalOpen
    ),
    updateDocumentModalOpen: useSelector(
      (state: any) => state.modal.updateDocumentModalOpen
    ),
    updateTemplateDocumentModalOpen: useSelector(
      (state: any) => state.modal.updateTemplateDocumentModalOpen
    ),
    updateMeetingModalOpen: useSelector(
      (state: any) => state.modal.updateMeetingModalOpen
    ),
    updateTemplateMeetingModalOpen: useSelector(
      (state: any) => state.modal.updateTemplateMeetingModalOpen
    ),
    deleteProductionModalOpen: useSelector(
      (state: any) => state.modal.deleteProductionModalOpen
    ),
    deleteDocumentModalOpen: useSelector(
      (state: any) => state.modal.deleteDocumentModalOpen
    ),
    deleteTemplateDocumentModalOpen: useSelector(
      (state: any) => state.modal.deleteTemplateDocumentModalOpen
    ),
    deleteMeetingModalOpen: useSelector(
      (state: any) => state.modal.deleteMeetingModalOpen
    ),
    deleteTemplateMeetingModalOpen: useSelector(
      (state: any) => state.modal.deleteTemplateMeetingModalOpen
    ),
    deleteUserModalOpen: useSelector(
      (state: any) => state.modal.deleteUserModalOpen
    ),
    deleteProductionTemplateModalOpen: useSelector(
      (state: any) => state.modal.deleteProductionTemplateModalOpen
    ),
  };
};

export default useModalState;
