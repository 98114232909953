import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useDispatch } from "store";
import { closeDeleteTemplateMeetingModal } from "store/slices/modal";
import { templateClient } from "services/api/manual/template";
import { TemplateState } from "types/template";
import { getTemplateMeetings } from "store/slices/templates";

const DeleteTemplateMeetingModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );
  const templateId = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate?.id
  );
  const deleteTemplateMeetingModalOpen = useSelector(
    (state: any) => state.modal.deleteTemplateMeetingModalOpen
  );
  const deleteTemplateMeetingModalParams = useSelector(
    (state: any) => state.modal.deleteTemplateMeetingModalParams.id
  );
  const meetingList = useSelector(
    (state: TemplateState) => state.templates.templateMeetings
  );
  const selectedMeeting = meetingList.find(
    (item: any) => item.meetingId === deleteTemplateMeetingModalParams
  );

  const handleDelete = async (meetingId: number) => {
    if (meetingId) {
      try {
        setLoading(true);
        await templateClient.deleteTemplateMeeting(meetingId);
        setLoading(false);
        toast.success("Successfully deleted meeting");
        dispatch(getTemplateMeetings(productionCompanyId!, templateId!));
      } catch (error) {
        toast.error("Error deleting meeting");
        console.error("Error:", error);
      } finally {
        dispatch(closeDeleteTemplateMeetingModal());
      }
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiPaper-root": { p: "8px" } }}
      open={deleteTemplateMeetingModalOpen}
      onClose={() => dispatch(closeDeleteTemplateMeetingModal())}
    >
      <DialogTitle variant="h2" sx={{ py: 4 }}>
        {`Delete "${selectedMeeting?.title}"?`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete{" "}
              <strong style={{ fontFamily: "inherit" }}>
                {selectedMeeting?.title}
              </strong>
              ?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: "16px 24px" }}>
        <Button
          sx={{
            boxShadow: "none",
            background: "#eb1e13",
            "&:hover": {
              boxShadow: "none",
              color: "#eb1e13",
              background: "#fff",
              border: "1px solid #eb1e13",
            },
          }}
          variant="contained"
          disabled={loading}
          onClick={() => handleDelete(selectedMeeting?.meetingId!)}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "#000",
              }}
              size={20}
            />
          ) : (
            "Delete"
          )}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            dispatch(closeDeleteTemplateMeetingModal());
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTemplateMeetingModal;
