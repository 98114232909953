import { useState } from "react";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useField,
} from "formik";
import * as Yup from "yup";

import { CategoryItem, Region, categories, regions } from "./modalData";

import { useDispatch } from "store";
import { closeCreateTemplateDocumentModal } from "store/slices/modal";
import DragDrop from "components/DragDrop/DragDrop";
import { templateClient } from "services/api/manual/template";
import { TemplateState } from "types/template";
import { getTemplateById } from "store/slices/templates";
import { ProductionCompanyState } from "types/productionCompany";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

console.log({ categories });

interface FormValues {
  documentTitle: string;
  documentCategory: number | string;
  documentSubCategory: number | string;
  documentRegion: number | string;
  documentFile: File | string;
}

interface FormikErrorTextProps {
  children?: React.ReactNode;
}

interface FormikSelectProps extends FieldProps {
  label: string;
  children: React.ReactNode;
}

const FormikErrorText = ({ children }: FormikErrorTextProps) => (
  <Typography style={{ color: "red", marginTop: 4, marginLeft: 4 }}>
    {children}
  </Typography>
);

interface FormikSelectProps extends FieldProps {
  label: string;
  children: React.ReactNode;
}

const FormikSelect = ({ label, children, ...props }: FormikSelectProps) => {
  const [field, meta] = useField(props.field.name);

  return (
    <FormControl
      sx={{
        "& .MuiSelect-select": {
          background: FORM_FIELD_BG_COLOR,
        },
        "& .MuiFormHelperText-root": { background: "#fff" },
      }}
      fullWidth
      error={meta.touched && !!meta.error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props.field}
        label={label}
        error={meta.touched && !!meta.error}
      >
        {children}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const CreateTemplateDocumentModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const prodCompanyId = useSelector(
    (state: ProductionCompanyState) =>
      state.productionCompany.selectedProductionCompany?.productionCompanyId
  );

  const selectedTemplate = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate
  );
  const createTemplateDocumentModalOpen = useSelector(
    (state: any) => state.modal.createTemplateDocumentModalOpen
  );

  const initialValues: FormValues = {
    documentTitle: "",
    documentCategory: "",
    documentSubCategory: "",
    documentRegion: "",
    documentFile: "",
  };

  const validationSchema = Yup.object().shape({
    documentTitle: Yup.string().required("Document title is required"),
    documentCategory: Yup.string().required("Document category is required"),
    documentRegion: Yup.string().required("Document region is required"),
    documentFile: Yup.mixed()
      .required("A file is required")
      .test(
        "fileExists",
        "A file is required",
        (value: any) => value && value.size > 0
      ),
  });

  function getDocumentCategoryId(categoryName: string | number) {
    const filteredCategory = categories.find(
      (item) => item.category?.name === categoryName
    );
    return filteredCategory?.category?.id;
  }

  function getDocumentSubCategoryId(subCategoryName: string | number) {
    for (let categoryItem of categories) {
      const subCategory = categoryItem.subCategories.find(
        (sub) => sub.name === subCategoryName
      );
      if (subCategory) {
        return subCategory.id;
      }
    }

    return null;
  }

  const handleCreate = async (values: FormValues) => {
    if (values) {
      let documentFileName = "";

      if (values.documentFile instanceof File) {
        documentFileName = values.documentFile.name;
      }

      const document = {
        category: {
          categoryId: getDocumentCategoryId(values.documentCategory),
        },
        documentFileName,
        documentInfo: "",
        documentName: values.documentTitle,
        region: {
          regionId: values.documentRegion,
        },
        subcategory: {
          subCategoryId:
            getDocumentSubCategoryId(values.documentSubCategory) || 0,
        },
        updateInfo: "",
      };

      try {
        setLoading(true);
        await templateClient.createTemplateDocument(
          document,
          selectedTemplate?.id!,
          values.documentFile as File
        );
        setLoading(false);
        toast.success("Successfully Added Document");
        dispatch(getTemplateById(prodCompanyId!, selectedTemplate?.id!));
      } catch (error) {
        toast.error("Error creating document");
        console.error("Error in deleting document:", error);
        dispatch(getTemplateById(prodCompanyId!, selectedTemplate?.id!));
      } finally {
        dispatch(closeCreateTemplateDocumentModal());
      }
    }
  };

  const onSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    handleCreate(values);
    setSubmitting(false);
  };

  const getSubCategories = (categoryName: string) => {
    const category = categories.find((c) => c.category?.name === categoryName);
    return category ? category?.subCategories : [];
  };

  return (
    <Dialog
      open={createTemplateDocumentModalOpen}
      onClose={() => dispatch(closeCreateTemplateDocumentModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Add a document
      </DialogTitle>

      <Box sx={{ p: "0px 0px 16px 16px" }}>
        <Typography variant="caption">
          Please fill all required fields below.
        </Typography>
      </Box>

      <Box>
        <Divider />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          isSubmitting,
          resetForm,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form id="create-document-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="documentTitle"
                  name="documentTitle"
                  label="Document Title"
                  fullWidth
                  error={Boolean(errors.documentTitle && touched.documentTitle)}
                  helperText={touched.documentTitle && errors.documentTitle}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentCategory"
                  label="Document Category"
                  fullWidth
                  error={Boolean(
                    errors.documentCategory && touched.documentCategory
                  )}
                  helperText={
                    touched.documentCategory && errors.documentCategory
                  }
                >
                  {categories.map(({ category }: CategoryItem) => {
                    if (category?.name === "") {
                      return null;
                    }
                    return (
                      <MenuItem key={nanoid()} value={category?.name}>
                        {category?.name}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentSubCategory"
                  label="Document Subcategory"
                  fullWidth
                  error={Boolean(
                    errors.documentSubCategory && touched.documentSubCategory
                  )}
                  helperText={
                    touched.documentSubCategory && errors.documentSubCategory
                  }
                >
                  {getSubCategories(String(values.documentCategory!) || "").map(
                    (subcategory) => {
                      if (subcategory === null) {
                        return null;
                      }
                      return (
                        <MenuItem key={nanoid()} value={subcategory.name}>
                          <Typography>{subcategory.name}</Typography>
                        </MenuItem>
                      );
                    }
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Field
                  component={FormikSelect}
                  name="documentRegion"
                  label="Region"
                  fullWidth
                  error={Boolean(
                    errors.documentRegion && touched.documentRegion
                  )}
                  helperText={touched.documentRegion && errors.documentRegion}
                >
                  {regions.map((region: Region) => (
                    <MenuItem key={nanoid()} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} sm={12}>
                <DragDrop
                  onFileSelect={(file) => {
                    setFieldValue("documentFile", file);
                  }}
                />
                <ErrorMessage name="documentFile" component={FormikErrorText} />
              </Grid>

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => {
                        dispatch(closeCreateTemplateDocumentModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateTemplateDocumentModal;
