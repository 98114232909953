import React from "react";
import { useSelector } from "react-redux";

import useModalState from "../../../../hooks/useModalState";

import CreateTemplateMeetingModal from "components/Modals/CreateTemplateMeetingModal";
import CreateTemplateModal from "components/Modals/CreateTemplateModal";
import DeleteTemplateModal from "components/Modals/DeleteTemplateModal";
import DeleteTemplateMeetingModal from "components/Modals/DeleteTemplateMeetingModal";
import UpdateTemplateMeetingModal from "components/Modals/UpdateTemplateMeetingModal";
import UpdateTemplateDocumentModal from "components/Modals/UpdateTemplateDocumentModal";

const TemplateModals = () => {
  const modalState = useModalState();

  const updateTemplateDocumentModalOpen = useSelector(
    (state: any) => state.modal.updateTemplateDocumentModalOpen
  );

  console.log(updateTemplateDocumentModalOpen);

  return (
    <>
      {modalState.createTemplateMeetingModalOpen && (
        <CreateTemplateMeetingModal />
      )}
      {modalState.createTemplateModalOpen && <CreateTemplateModal />}
      {modalState.updateTemplateMeetingModalOpen && (
        <UpdateTemplateMeetingModal />
      )}
      {modalState.updateTemplateDocumentModalOpen && (
        <UpdateTemplateDocumentModal />
      )}
      {modalState.deleteProductionTemplateModalOpen && <DeleteTemplateModal />}
      {modalState.deleteTemplateMeetingModalOpen && (
        <DeleteTemplateMeetingModal />
      )}
    </>
  );
};

export default TemplateModals;
