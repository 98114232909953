import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import DeleteMeetingModal from "./DeleteMeetingModal";

import { useDispatch } from "store";
import { closeDeleteDocumentModal } from "store/slices/modal";
import { getDocuments } from "store/slices/document";
import { documentClient } from "services/api/manual";

const DeleteDocumentModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const showId = useSelector((state: any) => state.show.selectedShow.showId);
  const deleteDocumentModalOpen = useSelector(
    (state: any) => state.modal.deleteDocumentModalOpen
  );
  const deleteDocumentModalParamsId = useSelector(
    (state: any) => state.modal.deleteDocumentModalParams.id
  );
  const documentList = useSelector((state: any) => state.document.documents);
  const selectedDocument = documentList.find(
    (item: any) => item.documentId === deleteDocumentModalParamsId
  );
  const deleteMeetingModalOpen = useSelector(
    (state: any) => state.modal.deleteMeetingModalOpen
  );

  const handleDelete = async (documentId: number) => {
    if (documentId) {
      try {
        setLoading(true);
        await documentClient.archiveDocument(documentId);
        setLoading(false);
        toast.success("Successfully deleted and archived");
        dispatch(getDocuments(showId, navigate));
      } catch (error) {
        setLoading(false);
        toast.error("Error deleting document");
        dispatch(closeDeleteDocumentModal());
        console.error("Error:", error);
      } finally {
        setLoading(false);
        dispatch(closeDeleteDocumentModal());
      }
    }
  };

  return (
    <Box>
      <Dialog
        sx={{ "& .MuiPaper-root": { p: "8px" } }}
        open={deleteDocumentModalOpen}
        onClose={() => dispatch(closeDeleteDocumentModal())}
      >
        <DialogTitle variant="h2" sx={{ py: 4 }}>
          {`Delete "${selectedDocument.documentName}"?`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to delete{" "}
                <strong style={{ fontFamily: "inherit" }}>
                  {selectedDocument.documentName}
                </strong>
                ?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: "16px 24px" }}>
          <Button
            sx={{
              boxShadow: "none",
              background: "#eb1e13",
              "&:hover": {
                boxShadow: "none",
                color: "#eb1e13",
                background: "#fff",
                border: "1px solid #eb1e13",
              },
            }}
            disabled={loading}
            variant="contained"
            onClick={() => handleDelete(selectedDocument.documentId)}
          >
            {loading ? (
              <CircularProgress
                style={{
                  color: "#000",
                }}
                size={20}
              />
            ) : (
              "Delete"
            )}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              dispatch(closeDeleteDocumentModal());
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {deleteMeetingModalOpen && <DeleteMeetingModal />}
    </Box>
  );
};

export default DeleteDocumentModal;
