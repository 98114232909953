import { useState } from "react";
import Box from "@mui/material/Box";
import { Tabs, Tab } from "@mui/material";
import { useSelector } from "react-redux";

import { PROD_TEMPLATE_TAB_LABELS } from "../../../../constants";

import AdminTemplateContactForm from "./AdminTemplateContactForm";
import AdminTemplateWelcomeMessageForm from "./AdminTemplateWelcomeMessageForm";
import AdminTemplateMeetingList from "./AdminTemplateMeetingList";
import AdminTemplateDocumentList from "./AdminTemplateDocumentList";

import { useCustomTheme } from "themes";
import { Meeting } from "types/meeting";
import {
  categories,
  regions,
  CategoryItem,
  Region,
} from "components/Modals/modalData";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: "32px" }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SelectionTab = () => {
  const theme = useCustomTheme();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const meetingDataRows = useSelector(
    (state: any) => state.templates.templateMeetings
  );

  const documentDataRows = useSelector(
    (state: any) => state.templates.templateDocuments
  );

  const getCategoryName = (categories: CategoryItem[], categoryId: number) => {
    const filteredCategory = categories.find(
      (category: any) => category.category.id === categoryId
    );
    if (filteredCategory) {
      return filteredCategory.category?.name || "";
    }
    return "";
  };

  const findSubCategoryName = (
    categories: CategoryItem[],
    sunCategoryId: number
  ) => {
    for (let category of categories) {
      const subCategory = category.subCategories.find(
        (subCategory) => subCategory.id === sunCategoryId
      );
      if (subCategory) {
        return subCategory.name;
      }
    }
    return "";
  };

  const getRegionName = (regions: Region[], regionId: number) => {
    const filteredRegion = regions.find(
      (region: any) => region.id === regionId
    );
    if (filteredRegion) {
      return filteredRegion.name;
    }
    return "";
  };

  function createMeetingData({
    meetingId,
    title,
    agenda,
    duration,
    location,
    attendeesProd,
    attendeesUis,
    meetingInfo,
  }: Meeting) {
    return {
      id: meetingId,
      title: title,
      agenda: agenda,
      duration: duration,
      location: location,
      uisAttendees: attendeesUis,
      prodAttendees: attendeesProd,
      meetingInfo: meetingInfo,
    };
  }

  const meetingRows = meetingDataRows.map((row: Meeting) => {
    return createMeetingData({
      meetingId: row.meetingId,
      title: row.title,
      agenda: row.agenda,
      duration: row.duration,
      location: row.location,
      attendeesUis: row.attendeesUis,
      attendeesProd: row.attendeesProd,
      meetingInfo: row.meetingInfo,
    });
  });

  function createDocumentData(
    documentId: number,
    documentName: string,
    category: string,
    subcategory: string,
    region: string
  ): any {
    return {
      id: documentId,
      documentName: documentName,
      category: category,
      subcategory: subcategory,
      region: region,
    };
  }

  const documentRows = documentDataRows.map(
    (row: {
      documentId: number;
      documentName: string;
      category: any;
      subcategory: any;
      region: any;
    }) => {
      return createDocumentData(
        row.documentId,
        row.documentName,
        getCategoryName(categories, row.category.categoryId),
        findSubCategoryName(categories, row.subcategory.subCategoryId),
        getRegionName(regions, row.region.regionId)
      );
    }
  );

  const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
    setSelectedIndex(newIndex);
  };

  const getTabStyle = (index: number) => {
    const isSelected = index === selectedIndex;
    const baseStyle = {
      background: isSelected ? "#ddd" : "#fff",
      borderBottom: isSelected ? "2px solid #000" : "none",
      border: `px solid ${theme.palette.tertiary.main}`,
    };

    if (index === 0) {
      return {
        ...baseStyle,
        borderTopLeftRadius: "8px",
      };
    } else if (index === PROD_TEMPLATE_TAB_LABELS.length - 1) {
      return {
        ...baseStyle,
        borderTopRightRadius: "8px",
      };
    } else {
      return baseStyle;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedIndex}
          onChange={handleChange}
          variant="fullWidth"
          allowScrollButtonsMobile
          aria-label="full width tabs"
        >
          {PROD_TEMPLATE_TAB_LABELS.map((label: string, index: number) => (
            <Tab
              key={label}
              sx={getTabStyle(index)}
              label={label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {PROD_TEMPLATE_TAB_LABELS.map((label: string, index: number) => (
        <TabPanel key={index} value={selectedIndex} index={index}>
          {index === 0 && <AdminTemplateWelcomeMessageForm />}

          {index === 1 && <AdminTemplateContactForm />}

          {index === 2 && <AdminTemplateMeetingList rows={meetingRows} />}

          {index === 3 && <AdminTemplateDocumentList rows={documentRows} />}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SelectionTab;
