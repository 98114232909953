import { ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IconPlus, IconTrash, IconEdit } from "@tabler/icons-react";
import { nanoid } from "nanoid";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import {
  Button,
  Grid,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";

import { DOC_REGIONS } from "../../../constants";

import MainCard from "components/Cards/MainCard";
import { useDispatch, useSelector } from "store";

// A type that represents the possible ordering
type Order = "asc" | "desc";

// Comparator that handles both numeric and string comparison
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const isNumeric = (value: any) => !isNaN(value) && isFinite(value);

  // Handle strings with numeric prefixes
  if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
    const getLeadingNumber = (str: string): number => {
      const match = str.match(/^\d+/);
      return match ? parseInt(match[0], 10) : NaN; // Return NaN if no leading number
    };

    const numA = getLeadingNumber(a[orderBy] as string);
    const numB = getLeadingNumber(b[orderBy] as string);

    // If both strings start with numbers, compare them numerically
    if (!isNaN(numA) && !isNaN(numB)) {
      return numB - numA;
    }

    // If either string doesn't start with a number, compare alphabetically
    return (b[orderBy] as string).localeCompare(
      a[orderBy] as string,
      undefined,
      {
        sensitivity: "base",
      }
    );
  }

  // If not strings, handle the comparison for numeric or other types
  if (isNumeric(a[orderBy]) && isNumeric(b[orderBy])) {
    return (b[orderBy] as number) - (a[orderBy] as number);
  }

  // Fallback for other types
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Helper function to get the comparator function based on the order
const getComparator = <T extends Record<string, any>>(
  order: Order,
  orderBy: keyof T
) => {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
};

// Stable sorting function to preserve original order for equal elements
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ m: 0, px: "16px" }}>
          <Checkbox
            color="primary"
            sx={{ mr: "32px" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell: any) => (
          <TableCell
            sx={{ fontWeight: "bold", m: 0, p: "16px" }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {headCell.label}
              </Typography>

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {numSelected <= 0 && (
          <TableCell
            sortDirection={false}
            align="right"
            sx={{ pr: 5, fontWeight: "bold" }}
          >
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

// ==============================|| Toolbar||============================== //

interface EnhancedTableToolbarProps {
  numSelected: number;
  ids: number[];
  deleteMultiple?: (ids: number[]) => void;
  handleRefresh: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, deleteMultiple, ids, handleRefresh } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (deleteMultiple && ids.length > 0) {
      try {
        setLoading(true);
        await deleteMultiple(ids);
        setLoading(false);
        toast.success("Successfully deleted meetings");
        handleRefresh();
      } catch (error) {
        setLoading(false);
        toast.error("Error deleting meetings");
        console.error("Error in deleting meetings:", error);
      }
    }
  };

  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          color: (theme) => theme.palette.secondary.main,
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography color="primary" variant="h4">
          {numSelected} Selected
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete selected">
          <IconButton disabled={loading} onClick={handleClick}>
            {loading ? (
              <CircularProgress
                style={{
                  color: "#000",
                }}
                size={14}
              />
            ) : (
              <IconTrash />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

// ==============================|| Heading Component||============================== //

interface HeadingComponentProps {
  categoryTitle: string;
  categoryId: number | undefined;
  onAdd: any;
  disableAdd: boolean;
  SecondaryAddButton?: boolean;
  onSecondaryAdd?: any;
  secondaryActionButtonTitle?: string;
  SecondaryAddButtonDisabled?: boolean;
}

const HeadingComponent = ({
  categoryTitle,
  categoryId,
  onAdd,
  disableAdd,
  SecondaryAddButton,
  onSecondaryAdd,
  secondaryActionButtonTitle,
  SecondaryAddButtonDisabled,
}: HeadingComponentProps) => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        p: "8px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Button
          disabled={disableAdd}
          onClick={() => dispatch(onAdd({ documentCategory: categoryId }))}
        >
          <IconPlus />
          <Typography sx={{ pl: 1 }}>Add {categoryTitle}</Typography>
        </Button>
        {SecondaryAddButton && (
          <Button
            sx={{ ml: 2 }}
            disabled={SecondaryAddButtonDisabled}
            onClick={() =>
              dispatch(onSecondaryAdd({ documentCategory: categoryId }))
            }
          >
            <IconPlus />
            <Typography sx={{ pl: 1 }}>
              Add {secondaryActionButtonTitle}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

// ==============================|| Region Filter||============================== //

const RegionFilter = () => {
  const [selectedRegion, setSelectedRegion] = useState<string>("All");

  const handleChange = (event: SelectChangeEvent<string>) => {
    const region = event.target.value;
    setSelectedRegion(region);
  };

  return (
    <FormControl sx={{ minWidth: "110px" }}>
      <InputLabel
        sx={{
          backgroundColor: "white",
          padding: "0 4px",
        }}
        id="region-select-label"
      >
        Filter by region
      </InputLabel>
      <Select
        labelId="region-select-label"
        id="region-select"
        value={selectedRegion}
        label="All Documents"
        onChange={handleChange}
        sx={{
          textAlign: "center",
          "& .MuiSelect-select": {
            textAlignLast: "center",
          },
        }}
      >
        {DOC_REGIONS.map((Regions: string) => {
          return (
            <MenuItem key={nanoid()} value={Regions}>
              {Regions}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

interface AdminListProps {
  title: string;
  subtitle?: string;
  category?: string;
  subcategory?: string;
  searchComponent?: boolean;
  regionFilter?: boolean;
  headingInfoComponent?: ReactNode;
  editIcon?: boolean;
  deleteIcon?: boolean;
  categoryTitle: string;
  categoryId?: number | undefined;
  rows: any;
  onEdit?: any;
  editDispatch?: boolean;
  disableEdit?: boolean;
  onDelete?: any;
  disableDelete?: boolean;
  onAdd?: any;
  disableAdd?: boolean;
  editTooltipText?: string;
  headCells: any;
  searchProp: string;
  deleteMultiple?: any;
  handleRefresh?: any;
  disableDeleteAll?: boolean;
  searchPlaceholder?: string;
  SecondaryAddButton?: boolean;
  onSecondaryAdd?: any;
  secondaryActionButtonTitle?: string;
  SecondaryAddButtonDisabled?: boolean;
}

const AdminList = ({
  title,
  subtitle,
  searchComponent = false,
  regionFilter = false,
  editIcon = true,
  deleteIcon = true,
  categoryTitle,
  categoryId,
  rows,
  onEdit,
  editDispatch = true,
  disableEdit = false,
  onDelete,
  disableDelete = false,
  onAdd,
  disableAdd = false,
  editTooltipText,
  headCells,
  searchProp,
  deleteMultiple,
  handleRefresh,
  disableDeleteAll = false,
  searchPlaceholder = "Search documents",
  SecondaryAddButton = false,
  onSecondaryAdd,
  secondaryActionButtonTitle,
  SecondaryAddButtonDisabled = false,
}: AdminListProps) => {
  const dispatch = useDispatch();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<any>("documentName");
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState<string>("");
  const [filteredRows, setFilteredRows] = useState<any>([]);

  const searchTerm = useSelector((state: any) => state.searchTerm.searchTerm);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  interface HandleEditProps {
    editDispatch: boolean;
    row: any;
    categoryId: number;
    onEdit: (data: { id: number; documentCategory: number }) => void;
    dispatch: (action: any) => void;
  }

  function handleEdit({
    editDispatch,
    row,
    categoryId,
    onEdit,
    dispatch,
  }: HandleEditProps) {
    const editData = {
      id: row.id,
      documentCategory: categoryId,
    };

    if (editDispatch) {
      dispatch(onEdit(editData));
    }

    if (!editDispatch) {
      onEdit(editData);
    }
  }

  function handleRequestSort(event: React.MouseEvent<unknown>, property: any) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => Number(n.id));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    const newString =
      searchComponent && event ? event.target.value : searchTerm;
    setSearch(newString || "");

    const newRows = rows.filter((row: any) => {
      if (newString) {
        const properties = [searchProp];
        return properties.some((property) =>
          row[property]
            .toString()
            .toLowerCase()
            .includes(newString.toLowerCase())
        );
      }

      return true;
    });
    setFilteredRows(newRows);
  };

  const isSelected = (id: number) => selected.includes(id);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <MainCard
      sx={{
        borderRadius: "8px",
      }}
      title={title}
      content={false}
      boxShadow={false}
      border={true}
      subtitle={subtitle}
      headingInfoComponent={
        <HeadingComponent
          categoryTitle={categoryTitle}
          categoryId={categoryId}
          onAdd={onAdd}
          disableAdd={disableAdd}
          SecondaryAddButton={SecondaryAddButton}
          onSecondaryAdd={onSecondaryAdd}
          secondaryActionButtonTitle={secondaryActionButtonTitle}
          SecondaryAddButtonDisabled={SecondaryAddButtonDisabled}
        />
      }
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            {searchComponent && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder={searchPlaceholder}
                value={search}
                size="small"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
            {regionFilter && <RegionFilter />}
          </Grid>
          {!disableDeleteAll && (
            <Grid item xs={12} sm={12}>
              <Typography sx={{ fontSize: "12px" }}>
                {`To delete multiple ${categoryTitle}, first, ensure to select each document by clicking 
              on its checkbox. Then, proceed by clicking the delete all icon located at the top 
              right corner of the list.`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>

      {selected.length > 0 && !disableDeleteAll && (
        <EnhancedTableToolbar
          handleRefresh={handleRefresh}
          ids={selected}
          numSelected={selected.length}
          deleteMultiple={deleteMultiple}
        />
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(filteredRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{ m: 0, px: "16px" }}
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>

                    {headCells.map((headCell: any) => {
                      return (
                        <TableCell
                          key={nanoid()}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={(event) => handleClick(event, row.id)}
                          sx={{ cursor: "pointer", m: 0, p: "16px" }}
                          align={headCell.align}
                        >
                          {row[headCell.id]}
                        </TableCell>
                      );
                    })}

                    <TableCell sx={{ p: "8px 16px" }} align="right">
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        {editIcon && (
                          <Tooltip title={editTooltipText}>
                            <IconButton
                              color="primary"
                              size="large"
                              aria-label="Edit"
                              disabled={disableEdit}
                              onClick={() =>
                                handleEdit({
                                  editDispatch,
                                  row,
                                  categoryId: categoryId ?? 0,
                                  onEdit,
                                  dispatch,
                                })
                              }
                            >
                              <IconEdit size={20} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {deleteIcon && (
                          <Tooltip title="Delete">
                            <IconButton
                              color="primary"
                              size="large"
                              aria-label="Delete"
                              disabled={disableDelete}
                              onClick={() =>
                                dispatch(
                                  onDelete({
                                    id: row.id,
                                    documentCategory: categoryId,
                                  })
                                )
                              }
                            >
                              <IconTrash size={20} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MainCard>
  );
};

export default AdminList;
