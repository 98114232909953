import { useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "store";
import { closeUpdateTemplateMeetingModal } from "store/slices/modal";
import { useCustomTheme } from "themes";
import { Meeting } from "types/meeting";
import { TemplateState } from "types/template";
import { templateClient } from "services/api/manual/template";
import { getTemplateMeetings } from "store/slices/templates";

const FORM_FIELD_BG_COLOR = "#f0f2f5";

interface FormValues {
  title: string;
  agenda: string;
  duration: string;
  location: string;
  uisAttendees: string;
  prodAttendees: string;
  meetingInfo: string;
}

const UpdateTemplateMeetingModal = () => {
  const theme = useCustomTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const productionCompanyId = useSelector(
    (state: any) =>
      state.productionCompany.selectedProductionCompany.productionCompanyId
  );
  const updateTemplateMeetingModalOpen = useSelector(
    (state: any) => state.modal.updateTemplateMeetingModalOpen
  );
  const updateTemplateMeetingModalParams = useSelector(
    (state: any) => state.modal.updateTemplateMeetingModalParams.id
  );
  const meetingList = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate?.meetings
  );
  const templateId = useSelector(
    (state: TemplateState) => state.templates.selectedTemplate?.id
  );
  const selectedMeeting = meetingList?.find(
    (item: Meeting) => item.meetingId === updateTemplateMeetingModalParams
  );

  console.log({ meetingList });
  console.log({ selectedMeeting });

  const initialValues: FormValues = {
    title: selectedMeeting?.title || "",
    agenda: selectedMeeting?.agenda || "",
    duration: selectedMeeting?.duration || "",
    location: selectedMeeting?.location || "",
    uisAttendees: selectedMeeting?.attendeesUis || "",
    prodAttendees: selectedMeeting?.attendeesProd || "",
    meetingInfo: selectedMeeting?.meetingInfo || "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Meeting title is required"),
    agenda: Yup.string().required("Meeting aganda is required"),
    duration: Yup.string().required("Meeting duration is required"),
    uisAttendees: Yup.string().required("UIS Attendees are required"),
    prodAttendees: Yup.string().required("Producton Attendees are required"),
  });

  const handleUpdate = async (values: FormValues) => {
    const meeting: Meeting = {
      meetingId: selectedMeeting?.meetingId!,
      title: values.title,
      duration: values.duration,
      location: values.location,
      agenda: values.agenda,
      meetingInfo: values.meetingInfo,
      attendeesUis: values.uisAttendees,
      attendeesProd: values.prodAttendees,
    };

    if (values) {
      try {
        setLoading(true);
        await templateClient.updateTemplateMeeting(templateId!, meeting);
        setLoading(false);
        toast.success("Successfully updated meeting");
        dispatch(getTemplateMeetings(productionCompanyId!, templateId!));
      } catch (error) {
        toast.error("Error updating meeting");
        console.error("Error in updating meeting:", error);
      } finally {
        dispatch(closeUpdateTemplateMeetingModal());
      }
    }
  };

  const onSubmit = (values: FormValues) => {
    handleUpdate(values);
  };

  return (
    <Dialog
      open={updateTemplateMeetingModalOpen}
      onClose={() => dispatch(closeUpdateTemplateMeetingModal())}
    >
      <DialogTitle variant="h2" sx={{ p: "16px" }}>
        Update a Meeting
      </DialogTitle>

      <Box sx={{ p: "0px 0px 16px 16px" }}>
        <Typography variant="caption">
          Please fill all required fields below.
        </Typography>
      </Box>

      <Box>
        <Divider />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form id="create-document-form">
            <Grid
              container
              spacing={3}
              sx={{
                p: "16px",
                width: "100%",
                margin: "0px",
                "& .MuiGrid-item": { p: "8px 0px" },
              }}
            >
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="title"
                  name="title"
                  label="Meeting Title"
                  fullWidth
                  error={Boolean(errors.title && touched.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="agenda"
                  name="agenda"
                  label="Meeting Agenda"
                  fullWidth
                  error={Boolean(errors.agenda && touched.agenda)}
                  helperText={touched.agenda && errors.agenda}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="location"
                  name="location"
                  label="Meeting Location"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": { background: "#fff" },
                  }}
                  id="duration"
                  name="duration"
                  label="Meeting Duration"
                  fullWidth
                  error={Boolean(errors.duration && touched.duration)}
                  helperText={touched.duration && errors.duration}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ pb: "16px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                    gutterBottom
                  >
                    Attendees
                  </Typography>
                  <Field
                    as={TextField}
                    sx={{
                      pb: "16px",
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": { background: "#fff" },
                    }}
                    id="uisAttendees"
                    name="uisAttendees"
                    label="UIS Team"
                    fullWidth
                    error={Boolean(errors.uisAttendees && touched.uisAttendees)}
                    helperText={touched.uisAttendees && errors.uisAttendees}
                  />
                  <Field
                    as={TextField}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        background: FORM_FIELD_BG_COLOR,
                      },
                      "& .MuiFormHelperText-root": { background: "#fff" },
                    }}
                    id="prodAttendees"
                    name="prodAttendees"
                    label="Production Team"
                    fullWidth
                    error={Boolean(
                      errors.prodAttendees && touched.prodAttendees
                    )}
                    helperText={touched.prodAttendees && errors.prodAttendees}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: FORM_FIELD_BG_COLOR,
                    },
                    "& .MuiFormHelperText-root": {
                      background: theme.palette.secondary.light,
                    },
                  }}
                  id="meetingInfo"
                  name="meetingInfo"
                  label="Additional Information"
                  multiline
                  rows={4}
                  fullWidth
                  autoComplete="additional-information"
                />
              </Grid>

              <Grid item xs={12}>
                <Grid sx={{ display: "flex", justifyContent: "end" }} container>
                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        boxShadow: "none",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#000",
                          }}
                          size={20}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>

                  <Grid sx={{ mx: "4px" }} item>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      onClick={() => {
                        dispatch(closeUpdateTemplateMeetingModal());
                      }}
                      sx={{
                        boxShadow: "none",
                        background: "#fff",
                        color: "#000",
                        "&:hover": {
                          color: "#000",
                          background: "#e6e6e6",
                          boxShadow: "none",
                          border: "1px solid #000",
                        },
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateTemplateMeetingModal;
