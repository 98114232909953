import { createSlice } from "@reduxjs/toolkit";

import { DefaultRootStateProps } from "types";

const initialState: DefaultRootStateProps["modal"] = {
  isOpen: false,

  createProductionModalOpen: false,
  createProductionModalParams: { id: null },
  createTemplateProductionModalOpen: false,
  createTemplateProductionModalParams: { id: null },
  createDocumentModalOpen: false,
  createDocumentModalParams: { documentCategory: null },
  createTemplateDocumentModalOpen: false,
  createTemplateDocumentModalParams: { documentCategory: null },
  createMeetingModalOpen: false,
  createMeetingModalParams: { id: null },
  createTemplateMeetingModalOpen: false,
  createTemplateMeetingModalParams: { id: null },
  createUserModalOpen: false,
  createUserModalParams: { id: null },
  createProductionTemplateModalOpen: false,
  createProductionTemplateModalParams: { id: null },

  updateDocumentModalOpen: false,
  updateDocumentModalParams: { id: null },
  updateTemplateDocumentModalOpen: false,
  updateTemplateDocumentModalParams: { id: null, documentCategory: null },
  updatePasswordModalOpen: false,
  updatePasswordModalParams: { id: null },
  updateMeetingModalOpen: false,
  updateMeetingModalParams: { id: null },
  updateTemplateMeetingModalOpen: false,
  updateTemplateMeetingModalParams: { id: null },

  deleteProductionModalOpen: false,
  deleteProductionModalParams: { id: null },
  deleteDocumentModalOpen: false,
  deleteDocumentModalParams: { id: null },
  deleteTemplateDocumentModalOpen: false,
  deleteTemplateDocumentModalParams: { id: null },
  deleteMeetingModalOpen: false,
  deleteMeetingModalParams: { id: null },
  deleteTemplateMeetingModalOpen: false,
  deleteTemplateMeetingModalParams: { id: null },
  deleteUserModalOpen: false,
  deleteUserModalParams: { id: null },
  deleteProductionTemplateModalOpen: false,
  deleteProductionTemplateModalParams: { id: null },

  archiveDocumentModalOpen: false,
  archiveDocumentModalParams: { id: null },
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    // Actions for MeetingsModal
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },

    // Actions for CreateDocumentModal
    openCreateDocumentModal: (state, action) => {
      state.createDocumentModalOpen = true;
      state.createDocumentModalParams.documentCategory =
        action.payload.documentCategory;
    },
    closeCreateDocumentModal: (state) => {
      state.createDocumentModalOpen = false;
    },

    // Actions for UpdateTemplateDocumentModal
    openUpdateTemplateDocumentModal: (state, action) => {
      state.updateTemplateDocumentModalOpen = true;
      state.updateTemplateDocumentModalParams = action.payload;
    },
    closeUpdateTemplateDocumentModal: (state) => {
      state.updateTemplateDocumentModalOpen = false;
    },

    // Actions for CreateDocumentModal
    openCreateTemplateDocumentModal: (state, action) => {
      state.createTemplateDocumentModalOpen = true;
      state.createTemplateDocumentModalParams.documentCategory =
        action.payload.documentCategory;
    },
    closeCreateTemplateDocumentModal: (state) => {
      state.createTemplateDocumentModalOpen = false;
    },

    // Actions for CreateProductionModal
    openCreateProductionModal: (state) => {
      state.createProductionModalOpen = true;
    },
    closeCreateProductionModal: (state) => {
      state.createProductionModalOpen = false;
    },

    // Actions for CreateProductionModal
    openCreateTemplateProductionModal: (state) => {
      state.createTemplateProductionModalOpen = true;
    },
    closeCreateTemplateProductionModal: (state) => {
      state.createTemplateProductionModalOpen = false;
    },

    // Actions for CreateMeetingModal
    openCreateMeetingModal: (state) => {
      state.createMeetingModalOpen = true;
    },
    closeCreateMeetingModal: (state) => {
      state.createMeetingModalOpen = false;
    },

    // Actions for CreateMeetingModal
    openCreateTemplateMeetingModal: (state) => {
      state.createTemplateMeetingModalOpen = true;
    },
    closeCreateTemplateMeetingModal: (state) => {
      state.createTemplateMeetingModalOpen = false;
    },

    // Actions for UpdatePasswordModal
    openUpdatePasswordModal: (state, action) => {
      state.updatePasswordModalOpen = true;
      state.updatePasswordModalParams.id = action.payload.id;
    },
    closeUpdatePasswordModal: (state) => {
      state.updatePasswordModalOpen = false;
    },

    // Actions for UpdateDocumentModal
    openUpdateDocumentModal: (state, action) => {
      state.updateDocumentModalOpen = true;
      state.updateDocumentModalParams.id = action.payload.id;
      state.createDocumentModalParams.documentCategory =
        action.payload.documentCategory;
    },
    closeUpdateDocumentModal: (state) => {
      state.updateDocumentModalOpen = false;
    },

    // Actions for UpdateMeetingModal
    openUpdateMeetingModal: (state, action) => {
      state.updateMeetingModalOpen = true;
      state.updateMeetingModalParams.id = action.payload.id;
    },
    closeUpdateMeetingModal: (state) => {
      state.updateMeetingModalOpen = false;
    },

    // Actions for UpdateMeetingModal
    openUpdateTemplateMeetingModal: (state, action) => {
      state.updateTemplateMeetingModalOpen = true;
      state.updateTemplateMeetingModalParams.id = action.payload.id;
    },
    closeUpdateTemplateMeetingModal: (state) => {
      state.updateTemplateMeetingModalOpen = false;
    },

    // Actions for DeleteProductionModal
    openDeleteProductionModal: (state, action) => {
      state.deleteProductionModalOpen = true;
      state.deleteProductionModalParams.id = action.payload.id;
    },
    closeDeleteProductionModal: (state) => {
      state.deleteProductionModalOpen = false;
    },

    // Actions for DeleteDocumentModal
    openDeleteDocumentModal: (state, action) => {
      state.deleteDocumentModalOpen = true;
      state.deleteDocumentModalParams.id = action.payload.id;
    },
    closeDeleteDocumentModal: (state) => {
      state.deleteDocumentModalOpen = false;
    },

    // Actions for DeleteDocumentModal
    openDeleteTemplateDocumentModal: (state, action) => {
      state.deleteTemplateDocumentModalOpen = true;
      state.deleteTemplateDocumentModalParams.id = action.payload.id;
    },
    closeDeleteTemplateDocumentModal: (state) => {
      state.deleteTemplateDocumentModalOpen = false;
    },

    // Actions for DeleteMeetingModal
    openDeleteMeetingModal: (state, action) => {
      state.deleteMeetingModalOpen = true;
      state.deleteMeetingModalParams.id = action.payload.id;
    },
    closeDeleteMeetingModal: (state) => {
      state.deleteMeetingModalOpen = false;
    },

    // Actions for DeleteMeetingModal
    openDeleteTemplateMeetingModal: (state, action) => {
      state.deleteTemplateMeetingModalOpen = true;
      state.deleteTemplateMeetingModalParams.id = action.payload.id;
    },
    closeDeleteTemplateMeetingModal: (state) => {
      state.deleteTemplateMeetingModalOpen = false;
    },

    // Actions for ArchiveDocumentModal
    openArchiveDocumentModal: (state, action) => {
      state.archiveDocumentModalOpen = true;
      state.archiveDocumentModalParams.id = action.payload.id;
    },
    closeArchiveDocumentModal: (state) => {
      state.archiveDocumentModalOpen = false;
    },

    // Actions for CreateUserModal
    openCreateUserModal: (state, action) => {
      state.createUserModalOpen = true;
      state.createUserModalParams.id = action.payload.id;
    },
    closeCreateUserModal: (state) => {
      state.createUserModalOpen = false;
    },

    // Actions for DeleteUserModal
    openDeleteUserModal: (state, action) => {
      state.deleteUserModalOpen = true;
      state.deleteUserModalParams.id = action.payload.id;
    },
    closeDeleteUserModal: (state) => {
      state.deleteUserModalOpen = false;
    },

    // Actions for CreateProductionTemplateModal
    openCreateProductionTemplateModal: (state, action) => {
      state.createProductionTemplateModalOpen = true;
      state.createProductionTemplateModalParams.id = action.payload.id;
    },
    closeCreateProductionTemplateModal: (state) => {
      state.createProductionTemplateModalOpen = false;
    },

    // Actions for DeleteProductionTemplateModal
    openDeleteProductionTemplateModal: (state, action) => {
      state.deleteProductionTemplateModalOpen = true;
      state.deleteProductionTemplateModalParams.id = action.payload.id;
    },
    closeDeleteProductionTemplateModal: (state) => {
      state.deleteProductionTemplateModalOpen = false;
    },
  },
});

export const {
  openModal,
  closeModal,
  openUpdatePasswordModal,
  closeUpdatePasswordModal,
  openCreateProductionModal,
  closeCreateProductionModal,
  openCreateTemplateProductionModal,
  closeCreateTemplateProductionModal,
  openDeleteProductionModal,
  closeDeleteProductionModal,
  openDeleteDocumentModal,
  closeDeleteDocumentModal,
  openDeleteTemplateDocumentModal,
  closeDeleteTemplateDocumentModal,
  openUpdateDocumentModal,
  closeUpdateDocumentModal,
  openCreateDocumentModal,
  closeCreateDocumentModal,
  openCreateTemplateDocumentModal,
  closeCreateTemplateDocumentModal,
  openCreateMeetingModal,
  closeCreateMeetingModal,
  openCreateTemplateMeetingModal,
  closeCreateTemplateMeetingModal,
  openUpdateMeetingModal,
  closeUpdateMeetingModal,
  openUpdateTemplateMeetingModal,
  closeUpdateTemplateMeetingModal,
  openDeleteMeetingModal,
  closeDeleteMeetingModal,
  openDeleteTemplateMeetingModal,
  closeDeleteTemplateMeetingModal,
  openArchiveDocumentModal,
  closeArchiveDocumentModal,
  openDeleteUserModal,
  closeDeleteUserModal,
  openCreateUserModal,
  closeCreateUserModal,
  openCreateProductionTemplateModal,
  closeCreateProductionTemplateModal,
  openDeleteProductionTemplateModal,
  closeDeleteProductionTemplateModal,
  openUpdateTemplateDocumentModal,
  closeUpdateTemplateDocumentModal,
} = modalSlice.actions;

export default modalSlice.reducer;
